.detail {
    background-color: white;
    height: 100%;
}

.product_detail_image {
    border-radius: 28px;
    padding: 20px;
    width: 380px;
    height: 380px;
    cursor: pointer;
    border: 1px solid black;
}

.product_detail_container {
    color: #324d67;
    display: flex;
    gap: 40px;
    margin: 50px;
   
}

.product_detail_desc {
    margin-top: -8px;
}

.product_detail_desc h1 {
    font-size: 30px;
    font-weight: 700;
    color: #324d67;
    width: 100%;
}

.reviews {
    color: #f02d34;
    margin-top: -12px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.reviews p {
    color: #324d67;
    margin-bottom: 10px;
}

.product_detail_desc h4 {
    margin-top: 13px;
    font-size: 15px;
    color: #324d67;
    font-weight: 700;
}

.product_detail_desc .price {
    font-weight: 700;
    font-size: 26px;
    margin-top: 17px;
    color: #f02d34;
}

.product_detail_desc p {
    margin-top: 10px;
    font-size: 16px;
}

.quantity {
    padding-bottom: 20px;
}

.quantity h3 {
    font-size: 17px;
    font-weight: 700;
    color: #324d67;
}

.product_detail_desc .quantity {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
}

.quantity_desc {
    border: 1px solid gray;
    padding: 6px;
}

.quantity_desc span {
    font-size: 16px;
    padding: 6px 12px;
    cursor: pointer;
}

.quantity_desc .minus {
    border-right: 1px solid gray;
    color: #f02d34;
}

.quantity_desc .num {
    border-right: 1px solid gray;
    font-size: 20px;
}

.quantity_desc .plus {
    color: rgb(49, 168, 49);
}

.product_detail_desc .buttons {
    display: flex;
    gap: 30px;
    margin-top: 60px;
}

.buttons .add_to_cart {
    border: 1px solid #f02d34;
    font-size: 19px;
    font-weight: 600;
    background-color: white;
    color: #f02d34;
    padding: 10px 20px;
    width: 200px;
    margin-top: 10px;
}

.buttons .buy_now {
    border: none;
    font-size: 19px;
    font-weight: 600;
    background-color: #f02d34;
    color: white;
    padding: 10px 20px;
    width: 200px;
    margin-top: 10px;
}

.small_images_container {
    display: flex;
    gap: 12px;
    margin-top: 20px;
}

.small_image {
    padding: 8px;
    border: 1px solid black;
    border-radius: 11px;
    width: 70px;
    height: 70px;
    cursor: pointer;
}


@media screen and (max-width:768px) {

    .product_detail_container {
        flex-wrap: wrap;
    }
    .product_detail_container
    .product_detail_image {
        width: 310px;
        height: 340px;
    }

    .product_detail_desc .buttons {
        display: flex;
    }
    
    .buttons .add_to_cart {
        width: 143px;
    }

    .buttons .buy_now {
        width: 143px;
    }
    .product_detail_container {
        margin: 20px;

    }

    .product_detail_desc p {
        margin-top: 10px;
        font-size: 16px;
    }

    .product_detail_desc h1 {
        font-size: 15px;
        font-weight: 700;
    }

    .product_detail_image {
        border-radius: 15px;
        padding: 20px;
        width: 195px;
        height: 360px;
        cursor: pointer;
        border: 1px solid black;
    }

    .small_image {
        padding: 8px;
        border: 1px solid black;
        width: 56px;
        height: 58px;
    }

    .product_detail_desc {
        margin-top: 8px;
    }
}
@media screen  and (max-width:1500px){

    .product_detail_container
    .product_detail_image {
        width: 310px;
        height: 340px;
    }

    .buttons .add_to_cart {
        width: 150px;
    }

    .buttons .buy_now {
        width: 150px;
    }
    .product_detail_container {
        margin: 20px;
        margin-left: 0px;
    }


    .product_detail_desc p {
        margin-top: 10px;
        font-size: 16px;
    }

    .product_detail_desc h1 {
        font-size: 15px;
        font-weight: 700;
    }

    .product_detail_image {
        border-radius: 15px;
        padding: 20px;
        width: 195px;
        height: 360px;
        cursor: pointer;
        border: 1px solid black;
    }

    .small_image {
        padding: 8px;
        border: 1px solid black;
        width: 56px;
        height: 58px;
    }

    .product_detail_desc {
        margin-top: 8px;
    }
}