.logo {
    padding-top: 4px;
    color: gray;
    font-size: 19px;
}

.navbar_container {
    display: flex;
    justify-content: space-between;
    margin: 3px 18px;
    position: relative;
    justify-content: space-between;
    padding-top: -10px;
}

.detail {
    background-color: white;
    height: 100%;
}

.cart_icon {
    border: none;
    color: gray;
    padding-top: -40px;
    padding-bottom: 18px;
    font-size: 25px;
    background-color: transparent;
}

.cart_item_qty {
    color: #eee;
    background-color: #f02d34;
    border-radius: 50%;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 31px;
    position: absolute;
    right: -8px;
    width: 18px;
    height: 28px;
    align-items: center;
}

.user_icon {
    margin-top: 8px;
    font-size: 25px;
}

.login_user {
    margin-left: 60rem;
    margin-right: -280px;
    cursor: pointer;
    position: relative;
}

.nav_button {
    background: linear-gradient(to right, rgba(249, 0, 104, 1) 0%, rgba(247, 117, 24, 1) 100%);
    border: none;
    color: white;
}
@media screen and (min-width:0px) and (max-width:300px){
    .login_user {
        margin-left: -15rem;
       }
}



@media screen and (min-width:301px) and (max-width:400px) {
    .login_user {
     margin-left: -10rem;
    }
}

@media screen and (min-width:401px) and (max-width:450px) {
    .login_user {
       margin-left: -5rem;
       
    }

    .card_details{
        width: 20%;
    }
}

@media screen and (min-width:450px) and (max-width:500px) {
    .login_user {
       margin-left: -2rem;
    }
}

@media screen and (min-width:501px) and (max-width:568px) {
    .login_user {
       margin-left: 1rem;
    }
}
@media screen and (min-width:569px) and (max-width:740px) {
    .login_user {
       margin-left: 8rem;
    }
}
@media screen and (min-width:741px) and (max-width:830px) {
    .login_user {
       margin-left: 14rem;
    }
}
@media screen and (min-width:831px) and (max-width:911px) {
    .login_user {
       margin-left: 19rem;
    }
}
@media screen and (min-width:911px) and (max-width:1000px) {
    .login_user {
       margin-left: 24rem;
    }
}
@media screen and (min-width:1001px) and (max-width:1200px) {
    .login_user {
       margin-left: 34rem;
    }
}
@media screen and (min-width:1201px) and (max-width:1300px) {
    .login_user {
       margin-left: 45rem;
    }
}
@media screen and (min-width:1301px) and (max-width:1500px) {
    .login_user {
       margin-left: 50rem;
    }
}
@media screen and (min-width:1501px) and (max-width:2500px) {
    .login_user {
       margin-left: 62rem;
    }
}