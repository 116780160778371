.login_div{
    font-family: Arial, Helvetica, sans-serif; 
}

.card {
    top: 16vh;
    border: none;
    border-radius: 20px;
    width: 410px !important;
    height: 450px;
    margin: 0 auto;
    background-color: #c3cbdb;
}

.signup {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 10px 50px
}

a {
    text-decoration: none !important;
}

h5 {
    color: #ff0147;
    margin-bottom: 3px;
    font-weight: bold
}

.para {
    color: rgba(0, 0, 0, 0.3);
    font-weight: 550;
}

input {
    width: 100%;
    display: block;
    margin-bottom: 7px
}

.form_control {
    border: 1px solid #936c7075;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, .075);
    letter-spacing: 1px
}

.form_control:focus {
   border: 1px solid #dc354575;
    border-radius: 30px;
    box-shadow: none;
    background-color: rgba(0, 0, 0, .075);
    color: #000;
    letter-spacing: 1px
}

.btn {
    display: block;
    width: 100%;
    border-radius: 30px;
    border: none;
    background: linear-gradient(to right, rgba(249, 0, 104, 1) 0%, rgba(247, 117, 24, 1) 100%);
}

.text_left {
    color: rgba(0, 0, 0, 0.3);
    font-weight: bold;
    padding-top: 2;
    margin-left: 1;
}

.text_right {
    color: #ff0147;
    font-weight: bold;
    padding-top: 2;
    margin-right: 1;
}

span.text-center {
    color: rgba(0, 0, 0, 0.3)
}
