.product_card {
    border: 1px solid black;
    height: 22rem;
    text-align: left;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    margin-left: 25px;
    box-shadow: 0 9px 9px rgba(0, 0, 0.1, 0.2);
}

.product_card:hover {
    transform: translateY(-5px);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

.product_image {
    width: 180px;
    height: 180px;
    margin-bottom: 10px;
}


.product_details {
    padding-top: 20px;
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product_name {
    line-height: 1.2;
    font-weight: 500;
    font-size: 17px;
    color: #324d67;
    margin: 0;
    text-align: left;
    width: 150px; /* add a fixed width */
    white-space: nowrap; /* prevent line breaks */
    overflow: hidden; /* hide overflow */
    text-overflow: ellipsis; /* show ellipsis for hidden text */
}

.product_price {
    padding-top: 10px;
    padding-right: 80px;
    font-weight: 800;
    color: black;
    margin: 0;
    width: 150px; /* add a fixed width */
}

.product_card {
    position: relative;
  }
  
  .wishlist {
    position: absolute;
    top: 8px;
    right: 12px; 
    cursor: pointer;
    font-size: 23px;
  }

@media screen and (min-width:0px) and (max-width:575px) {
    .product_card {
        height: 6.38rem;
    }

    .product_name {
        font-size: 14px;
    }

    .product_price {
        font-size: 18px;
    }
}

@media screen and (min-width:576px) and (max-width:767px) {

    .product_card {
        height: 8.38rem;
    }

    .product_name {
        font-size: 13px;
    }

    .product_price {
        font-size: 15px;
    }

    .product_image {
        width: 3rem;
        height: 3rem;
    }

}